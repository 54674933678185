import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBlogHomepageData } from '../../services/blogServices';

export const fetchBlogHomepageData = createAsyncThunk('/blog/homepage', async () => {
    try {
        const resp = await getBlogHomepageData(1);
        if (resp.data.code === 200) {
            return resp.data.data;
        } else {
            return Error('something went wrong');
        }
    } catch (error) {
        console.log(error);
    }
});

const blogHomepageSlice = createSlice({
    name: 'blogHomepage',
    initialState: {
        data: null,
        success: false,
        isFetching: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogHomepageData.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchBlogHomepageData.fulfilled, (state, action) => {
                state.isFetching = false;
                state.data = action.payload;
                state.success = true;
            })
            .addCase(fetchBlogHomepageData.rejected, (state, action) => {
                state.isFetching = false;
                state.data = null;
                state.error = action.payload;
                state.success = false;
            });
    },
});

export const selectBlogHomepage = (state) => state.blogHomeData;

export default blogHomepageSlice.reducer;
