import { createSlice } from '@reduxjs/toolkit';

const userData = createSlice({
    name: 'successPageData',
    initialState: {
        name: '',
        email: '',
        mobile: '',
        success: false,
    },
    reducers: {
        setUserData: (state, action) => {
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.mobile = action.payload.mobile;
            state.success = true;
        },
    },
});

export const { setUserData } = userData.actions;

export const selectSuccessPage = (state) => state.successPage;

export default userData.reducer;
