import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBanner } from '../../services/otherServices';

export const fetchBannerData = createAsyncThunk('/website/banner', async () => {
    try {
        const resp = await getBanner();
        return {
            content: resp.data.data ? resp.data.data[0]?.content : '',
            link: resp.data.data ? resp.data.data[0]?.link : '',
        };
    } catch (error) {
        return {
            content: '',
            link: '',
        };
    }
});

const bannerSlice = createSlice({
    initialState: {
        content: '',
        link: '',
        isFetching: false,
        success: false,
    },
    name: 'topBanner',
    extraReducers: (builder) => {
        builder
            .addCase(fetchBannerData.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchBannerData.fulfilled, (state, action) => {
                state.content = action.payload.content;
                state.link = action.payload.link;
                state.isFetching = false;
                state.success = true;
            })
            .addCase(fetchBannerData.rejected, (state) => {
                state.success = false;
                state.isFetching = false;
                state.content = '';
                state.link = '';
            });
    },
});

export const selectTopBanner = (state) => state.topBanner;

export default bannerSlice.reducer;
