import http from './httpService';

/**************************************
    Trip User Account Services

    1. Get profile page data ->Checked
    2. Update profile page data ->Checked
    3. Add trip answer ->Checked
    4. Update trip answer ->Checked
    5. Update profile picture  ->Checked
    6 Get trip application details ->Checked

***************************************/

// Get user profile details (Profile page)
export function getUserProfile(data) {
  return http.post(`/api/user/profile`, data);
}

// Update user profile data (Profile page)
export function updateUserProfile(data) {
  return http.post('/api/user/profile/update', data);
}

// Add trip application data(answers) (Profile page)
export function addTripApplication(data) {
  return http.post(`/api/trips/application/add`, data);
}

// Update trip application data(anwer) (Profile page)
export function updateTripAnswer(data) {
  return http.post(`/api/trips/application/update`, data);
}

// Update user profile picture (Profile page)
export function updateProfilePicture(data) {
  return http.post(`/api/user/profile/image/upload`, data);
}

// Get user application data (questions & answers for a trip) (Profile page)
export function getUserApplication(data) {
  return http.post(`/api/trips/application/details`, data);
}
