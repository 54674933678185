import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTripApplications } from '../../../services/dashboardServices';

export const fetchDashboardTrips = createAsyncThunk('/api/dashboard/trips/list', async () => {
    try {
        const resp = await getTripApplications();
        const _upcomingTrips = [];

        if (resp.data.data.trips && resp.data.data.trips.length) {
            Array.from(resp.data.data.trips).forEach((item) => {
                if (new Date(item?.startDate) > new Date()) {
                    _upcomingTrips.push(item);
                }
            });
        }
        return _upcomingTrips;
    } catch (error) {
        return [];
    }
});

const profilePageTrips = createSlice({
    initialState: {
        trips: [],
        isFetching: true,
        success: false,
        error: null,
    },
    name: 'profilePageTrips',
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardTrips.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchDashboardTrips.fulfilled, (state, action) => {
                state.isFetching = false;
                state.success = true;
                state.error = null;
                state.trips = action.payload;
            })
            .addCase(fetchDashboardTrips.rejected, (state, action) => {
                state.error = action.payload;
                state.success = false;
                state.isFetching = false;
                state.trips = [];
            });
    },
});

export const selectProfilePageTrips = (state) => state.profilePageTrips;

export default profilePageTrips.reducer;
