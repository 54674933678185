import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTopSellingCourses } from '../../services/courseServices';

export const fetchTopSellingCourses = createAsyncThunk('/api/courses/top-selling', async () => {
    try {
        const res = await getTopSellingCourses();
        const { data } = res.data;
        if (data?.topSelling && data?.topSelling.length) {
            const _result = Array.from(data?.topSelling);

            const _upcomingRecent = Array.from(_result).filter(
                (item) => new Date() <= new Date(item?.Batches[0]?.startDate)
            );

            const _comingSoon = Array.from(_result).filter((item) => item?.Batches[0]?.startDate === '0000-00-00');

            const _expired = Array.from(_result).filter((item) => new Date() > new Date(item?.Batches[0]?.startDate));

            const _finalResult = [
                ..._upcomingRecent.sort(
                    (a, b) => new Date(a?.Batches[0]?.startDate) - new Date(b?.Batches[0]?.startDate)
                ),
                ..._comingSoon,
                ..._expired.sort((a, b) => new Date(b?.Batches[0]?.startDate) - new Date(a?.Batches[0]?.startDate)),
            ];

            const currentDate = moment(new Date()).format('YYYY-MM-DD');

            const _topSellings = [];

            _finalResult.map((item) => {
                if (item?.CouponDetails && item?.CouponDetails.length) {
                    let _coupons = Array.from(item?.CouponDetails);
                    const _noExpiryCoupons = _coupons.filter((item) => item?.Coupon?.endDate === '0000-00-00');
                    const _expiryCoupons = _coupons.filter(
                        (item) => moment(new Date(item?.Coupon?.endDate)) >= new Date(currentDate)
                    );
                    let _allCoupons = [..._noExpiryCoupons, ..._expiryCoupons].map((item) => ({ ...item?.Coupon }));

                    _allCoupons =
                        _allCoupons.length > 1 ? _allCoupons.sort((a, b) => b?.discount - a?.discount) : _allCoupons;

                    _topSellings.push({
                        ...item,
                        CouponDetails: _allCoupons,
                    });
                } else {
                    _topSellings.push(item);
                }
            });

            return _topSellings;
        }
    } catch (error) {
        console.log('Something went wrong ⚠️');
    }
});

const topSellingSlice = createSlice({
    initialState: {
        courses: [],
        isFetching: true,
        success: false,
    },
    name: 'topSellings',
    extraReducers: (builder) => {
        builder
            .addCase(fetchTopSellingCourses.pending, (state) => {
                state.isFetching = true;
                state.courses = [];
            })
            .addCase(fetchTopSellingCourses.fulfilled, (state, action) => {
                state.isFetching = false;

                if (action.payload && action.payload.length) {
                    state.courses = action.payload;
                } else {
                    state.courses = [];
                }
                state.success = true;
            })
            .addCase(fetchTopSellingCourses.rejected, (state) => {
                state.isFetching = false;
                state.courses = [];
            });
    },
});

export const selectTopSellings = (state) => state.topSellings;

export default topSellingSlice.reducer;
