import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserInfo } from '../../../services/dashboardServices';
import { setToken } from '../../../services/authServices';

export const fetchDashboardHomeData = createAsyncThunk('/api/dashboard/courses/home', async () => {
    let _user = localStorage.getItem('user');
    _user = JSON.parse(_user);
    setToken(localStorage.getItem('token'));
    try {
        const resp = await getUserInfo(parseInt(_user.id));
        const { data } = resp;

        const _enrolledCourses = [];
        let _upcoming = [];
        let _ongoing = [];
        let _completed = [];
        let _suggested = [];
        let _comingSoonBatches = [];


        // Suggested Courses
        if (data.data.suggestedCourses && data.data.suggestedCourses.length) {
            _suggested = data.data.suggestedCourses;
        }

        // Enrolled Courses
        if (data.data.enrolledCourses && data.data.enrolledCourses.length) {
            Array.from(data.data.enrolledCourses).forEach((item) => {
                _enrolledCourses.push({
                    isRefund: item?.isRefund,
                    isDashboardAccess: item?.isDashboardAccess,
                    isEnrolled: item?.isEnrolled,
                    ...item?.Batch,
                });
            });
        }

        if (_enrolledCourses.length) {
            // Completed
            _enrolledCourses.forEach((item) => {
                if (item?.Workshop?.type === 1) {
                    if (new Date(`${item?.endDate}T${item?.BatchSchedules[0]?.endTime}`) < new Date()) {
                        _completed.push(item);
                    }
                }
                if (item?.Workshop?.type === 2) {
                    if (new Date(`${item?.endDate}`) < new Date()) {
                        _completed.push(item);
                    }
                }
            });

            // Upcoming
            _enrolledCourses.forEach((item) => {
                if (item?.Workshop?.type === 1) {
                    if (new Date(`${item?.startDate}T${item?.BatchSchedules[0]?.startTime}`) > new Date()) {
                        _upcoming.push(item);
                    }
                }
                if (item?.Workshop?.type === 2) {
                    if (new Date(`${item?.startDate}`) > new Date()) {
                        _upcoming.push(item);
                    }
                }
            });

            // Ongoing
            _enrolledCourses.forEach((item) => {
                if (item?.Workshop?.type === 1) {
                    if (
                        new Date() >= new Date(`${item?.startDate}T${item?.BatchSchedules[0]?.startTime}`) &&
                        new Date() <= new Date(`${item?.endDate}T${item?.BatchSchedules[0]?.endTime}`)
                    ) {
                        _ongoing.push(item);
                    }
                }
                if (item?.Workshop?.type === 2) {
                    if (new Date() >= new Date(`${item?.startDate}`) && new Date() <= new Date(`${item?.endDate}`)) {
                        _ongoing.push(item);
                    }
                }
            });



            _enrolledCourses.forEach((item) => {
                if (item?.BatchSchedules && item?.BatchSchedules.length === 0) {
                    // console.log('Store :', item)
                    _comingSoonBatches.push({
                        comingSoon: true,
                        ...item
                    });
                }
            })

            _completed = _completed.sort((a, b) => new Date(b?.endDate) - new Date(a?.endDate));
            _ongoing = _ongoing.sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate));
            _upcoming = _upcoming.sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate));
        }

        const wsIds = [];
        Array.from([..._completed, ..._ongoing, ..._upcoming]).forEach((item) => {
            wsIds.push(item?.Workshop?.id);
        });

        return {
            completed: _completed,
            ongoing: _ongoing,
            upcoming: [..._upcoming, ..._comingSoonBatches],
            allCourses: [..._upcoming, ..._ongoing, ..._completed],
            suggested: _suggested.length ? _suggested : [],
            wsIds: Array.from(new Set(wsIds)),
        };
    } catch (error) {
        return {
            completed: [],
            ongoing: [],
            upcoming: [],
            suggested: [],
            wsIds: [],
        };
    }
});

const dashboardHomeSlice = createSlice({
    initialState: {
        courses: {
            ongoing: [],
            upcoming: [],
            completed: [],
            allCourses: [],
        },
        allWsIds: [],
        suggested: [],
        overviewData: {},
        success: false,
        isFetching: false,
        error: null,
    },
    name: 'dashboardHome',
    reducers: {
        setOverviewData: (state, action) => {
            state.overviewData = action.payload;
        },
        clearDashboardHomeData: (state) => {
            state.courses.allCourses = [];
            state.courses.ongoing = [];
            state.courses.upcoming = [];
            state.courses.allCourses = [];
            state.suggested = [];
            state.overviewData = {};
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardHomeData.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchDashboardHomeData.fulfilled, (state, action) => {
                state.isFetching = false;
                state.courses.ongoing = action.payload.ongoing;
                state.courses.upcoming = action.payload.upcoming;
                state.courses.completed = action.payload.completed;
                state.courses.allCourses = action.payload.allCourses;
                state.suggested = action.payload.suggested;
                state.success = true;
                state.error = null;
                state.wsIds = action.payload.wsIds;
            })
            .addCase(fetchDashboardHomeData.rejected, (state) => {
                state.isFetching = false;
                state.courses.ongoing = [];
                state.courses.upcoming = [];
                state.courses.completed = [];
                state.suggested = [];
                state.success = false;
                state.error = 'Something went wrong ⚠️';
            });
    },
});

export const { setOverviewData, clearDashboardHomeData } = dashboardHomeSlice.actions;

export const selectDashboardHome = (state) => state.dashboardHome;

export default dashboardHomeSlice.reducer;
