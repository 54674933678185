import http from './httpService';

/**************************************
    Authentication Services

    1.  Auth check (Check if user exist or not with entered email address) ->Checked
    2.  User registration ->Checked
    3.  User login ->Checked
    4.  Send password reset OTP ->Checked
    5.  Verify password reset OTP  ->Checked
    6.  Set new password ->Checked
    7.  Login with google account ->Checked
    8.  Set authorization token in http header & localStorage ->Checked
    9.  Get authorization token from localStorage ->Checked
    10. Get User email & id from localStorage ->Checked
    11. Check if user is logged in or not ->Checked
    12. Logout handler ->Checked
***************************************/

// Check if user exist or not
export function authUserCheck(data) {
    return http.post('/api/auth/check', data);
}

// New user register using email & password
export function authUserRegister(data) {
    return http.post('/api/auth/register', data);
}

// Login request
export function authUserLogin(data) {
    return http.post('/api/auth/login', data);
}

// Login without password (Verified User | Course purchased)
export function authPaymentSuccessLogin(orderId) {
    return http.post(`/api/auth/order/${orderId}/login`);
}

// Send password reset OTP
export function authSendOTP(data) {
    return http.post('/api/auth/send/otp', data);
}

// Password reset OTP verification
export function authOTPVerify(data) {
    return http.post('/api/auth/verify-otp', data);
}

// Set new password after OTP verification
export function authSetPassword(data) {
    return http.post('/api/auth/password/add', data);
}

// New user registration with google data
export function authGoogleSignIn(data) {
    return http.post('/api/auth/google/login', data);
}

// Get Guest User Details
export function authGetGuestInfo(id) {
    return http.get(`/api/auth/guest/${id}/info`);
}

// Moderator Login API
export function authModeratorLogin(data) {
    return http.post('/api/auth/moderator/access', data)
}

// Set token for logged in user
export function setToken(token) {
    if (token !== '' || token !== null || token !== 'null') {
        http.setToken(token);
        localStorage.setItem('token', token);
    } else {
        http.setToken('');
        localStorage.removeItem('token');
    }
}

// Get token for logged in user
export function getToken() {
    return localStorage.getItem('token');
}

// Get user name,email,id from localStorage
export function getUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
}

// Check if user has logged in or not
export const isLoggedIn = () => {
    const authToken = getToken();
    if (!authToken || !authToken === '' || !getUser() || authToken === 'null' || authToken === null) return false;
    return true;
};

// Logout handle
export const logoutHandle = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    if (typeof window !== 'undefined') return (window.location.href = window.location.origin + '/account/login');
};



