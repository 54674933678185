import http from './httpService';

/**************************************
   Masterclass , Courses & Categories related APIs

   1. Get website header data ->Checked
   2. Get all long-term courses ->Checked
   3. Get all masterclasses ->Checked
   4. Get homepage custom list for masterclasses & courses ->Checked
   5. Get top selling courses for All courses page ->Checked
   6. Get meta data for any course or masterclass (Product page) ->Checked
   7. Get product page data (Matsterclass & Course) ->Checked
   
***************************************/

// Website header data (Masterclass, Long-term courses & Upcoming/Previous trips)
export function getWebsiteHeaderData() {
  return http.get('/api/categories');
}

// All Long-term courses (Homepage & All courses page)
export function getAllCourses() {
  return http.get('/api/courses');
}

// All Masterclasses (Homepage & All courses page)
export function getAllMasterclasses() {
  return http.get('/api/workshops');
}

// Custom List View for Courses & Masterclasses (Website Homepage)
export function getHomepageCustomList() {
  return http.get('/api/homepage/sections/all');
}

// Top selling Courses (All courses page)
export function getTopSellingCourses() {
  return http.get(`/api/courses/top-selling`);
}

// Get product page meta data (Masterclass & Course page)
export function getWsMetaTags(id) {
  return http.get(`/api/workshop/${id}/tags`);
}

// Get product page details (masterclass & course page)
export function getProductPageDetails(id) {
  return http.get(`/api/workshop/${id}/details`);
}

// Get masterclass / course static landing page data
export function getLandingPageDetails(id) {
  return http.get(`/api/course/${id}/landing-page`);
}

// Get masterclass bonus data
export function getMasterclassBonus(wsId) {
  return http.get(`/api/ws/${wsId}/bonus`);
}
