import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllCourses } from '../../services/courseServices';

export const fetchLongTermCourses = createAsyncThunk('/api/courses', async () => {
    try {
        const res = await getAllCourses();
        const { data } = res.data;
        if (data?.categories && data?.categories?.length) {
            const _result = Array.from(data?.categories);
            const _allData = [];
            _result.forEach((cat) => {
                if (cat?.Subcategory && cat?.Subcategory.length) {
                    Array.from(cat?.Subcategory).forEach((subCat) => {
                        if (subCat?.CourseCategories && subCat?.CourseCategories.length) {
                            Array.from(subCat?.CourseCategories).forEach((ws) => {
                                _allData.push({
                                    ...ws?.Workshop,
                                    Category: {
                                        id: cat?.id,
                                        name: cat?.name,
                                    },
                                });
                            });
                        }
                    });
                }
            });

            const currentDate = moment(new Date()).format('YYYY-MM-DD');

            const _upcomingRecent = Array.from(_allData).filter(
                (item) => new Date() <= new Date(item?.Batches[0]?.startDate)
            );

            const _comingSoon = Array.from(_allData).filter((item) => item?.Batches[0]?.startDate === '0000-00-00');

            const _expired = Array.from(_allData).filter((item) => new Date() > new Date(item?.Batches[0]?.startDate));

            const _finalResult = [
                ..._upcomingRecent.sort(
                    (a, b) => new Date(a?.Batches[0]?.startDate) - new Date(b?.Batches[0]?.startDate)
                ),
                ..._comingSoon,
                ..._expired.sort((a, b) => new Date(b?.Batches[0]?.startDate) - new Date(a?.Batches[0]?.startDate)),
            ];

            const _allCourses = [];

            _finalResult.map((item) => {
                if (item?.CouponDetails && item?.CouponDetails.length) {
                    let _coupons = Array.from(item?.CouponDetails);
                    const _noExpiryCoupons = _coupons.filter((item) => item?.Coupon?.endDate === '0000-00-00');
                    const _expiryCoupons = _coupons.filter(
                        (item) => moment(new Date(item?.Coupon?.endDate)) >= new Date(currentDate)
                    );
                    let _allCoupons = [..._noExpiryCoupons, ..._expiryCoupons].map((item) => ({ ...item?.Coupon }));

                    _allCoupons =
                        _allCoupons.length > 1 ? _allCoupons.sort((a, b) => b?.discount - a?.discount) : _allCoupons;

                    _allCourses.push({
                        ...item,
                        CouponDetails: _allCoupons,
                    });
                } else {
                    _allCourses.push(item);
                }
            });

            return _allCourses;
        } else {
            return [];
        }
    } catch (error) {
        console.log('Something went wrong ⚠️');
    }
});

const coursesSlice = createSlice({
    initialState: {
        courses: [],
        isFetching: false,
        success: false,
    },
    name: 'courses',
    reducers: {
        getCourseById: (state, action) => {
            const course = state.courses.find((item) => item.id === action.payload.id);
            return course;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLongTermCourses.pending, (state) => {
                state.isFetching = true;
                state.courses = [];
            })
            .addCase(fetchLongTermCourses.fulfilled, (state, action) => {
                state.isFetching = false;
                if (action.payload && action.payload.length) {
                    state.courses = action.payload;
                } else {
                    state.courses = [];
                }
                state.success = true;
            })
            .addCase(fetchLongTermCourses.rejected, (state) => {
                state.isFetching = false;
                state.courses = [];
            });
    },
});

export const selectCourses = (state) => state.courses;
export default coursesSlice.reducer;
