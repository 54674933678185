import { createSlice } from '@reduxjs/toolkit';

const dashboardComp = createSlice({
    name: 'dashboardComp',
    initialState: {
        headerTitle: '',
    },
    reducers: {
        setHeaderTitle: (state, action) => {
            state.headerTitle = action.payload;
        },
    },
});

export const { setHeaderTitle } = dashboardComp.actions;

export const selectDashboardComp = (state) => state.dashboardComp;

export default dashboardComp.reducer;
