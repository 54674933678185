import http from './httpService';

/**************************************
  Travel page APIs

  1. Get travel landing page data ->Checked
  2. Get travel details page data ->Checked
  3. Get travel details page meta data ->Checked
   
***************************************/

// Trip landing page details (Trip landing page)
export function getTripHomepageDetails() {
  return http.get(`/api/trip/homepage`);
}

// Get trip details (Trip details page)
export function getTripDetails(id) {
  return http.get(`/api/trip/${id}/details`);
}

// Get trip details page meta data (Trip details page)
export function getTripMetaTags(id) {
  return http.get(`/api/trip/${id}/tags`);
}

// Send Trip Details
export function sendTripDetails(data) {
  return http.post(`/api/trips/itinerary/send`, data);
}
