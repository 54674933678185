import { createSlice } from '@reduxjs/toolkit';

const searchDataSlice = createSlice({
    name: 'searchData',
    initialState: {
        data: [],
        success: [],
    },
    reducers: {
        addSearchData: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { addSearchData } = searchDataSlice.actions;

export const selectSearchData = (state) => state.searchData;

export default searchDataSlice.reducer;
