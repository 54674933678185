import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserCertificates } from '../../../services/dashboardServices';

export const fetchMyCertificates = createAsyncThunk('/api/dashboard/user/certificates', async () => {
    try {
        const resp = await getUserCertificates();
        if (resp.data.data && resp.data.data.length) {
            const _certificates = Array.from(resp.data.data).filter((item) => item?.courseName);
            return _certificates;
        } else {
            return [];
        }
    } catch (error) {
        return [];
    }
});

const userCertificatesSlice = createSlice({
    initialState: {
        certificates: [],
        success: false,
        isFetching: true,
        error: null,
    },
    name: 'dashboardUserCertificates',
    reducers: {
        clearCertificatesData: (state) => {
            state.certificates = [];
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyCertificates.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchMyCertificates.fulfilled, (state, action) => {
                state.isFetching = false;
                state.certificates = action.payload;
                state.success = true;
                state.error = null;
            })
            .addCase(fetchMyCertificates.rejected, (state) => {
                state.isFetching = false;
                state.certificates = [];
                state.success = false;
                state.error = 'Something went wrong ⚠️';
            });
    },
});

export const { clearCertificatesData } = userCertificatesSlice.actions;

export const selectUserCertificates = (state) => state.userCertificates;

export default userCertificatesSlice.reducer;
