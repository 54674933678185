import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllMasterclasses } from '../../services/courseServices';
import moment from 'moment';
import { remove_duplicates } from '../../utils';

export const fetchMasterclasses = createAsyncThunk('/api/workshops', async () => {
    try {
        const res = await getAllMasterclasses();
        const { data } = res.data;

        let _allData = [];

        if (data?.categories && data?.categories?.length) {
            const _result = Array.from(data?.categories);
            _result.forEach((cat) => {
                if (cat?.Subcategory && cat?.Subcategory.length) {
                    Array.from(cat?.Subcategory).forEach((subCat) => {
                        if (subCat?.CourseCategories && subCat?.CourseCategories.length) {
                            Array.from(subCat?.CourseCategories).forEach((ws) => {
                                _allData.push({
                                    ...ws?.Workshop,
                                    Category: {
                                        id: cat?.id,
                                        name: cat?.name,
                                    },
                                });
                            });
                        }
                    });
                }
            });

            const currentDate = moment(new Date()).format('YYYY-MM-DD');

            _allData = remove_duplicates(_allData);

            let _upcoming = [];
            let _expired = [];
            let _comingSoon = [];

            const recordedCourse = _allData.filter(
                (item) => item?.Batches && item?.Batches.length && item?.Batches[0]?.sessionType === 2
            );
            const nonRecordedCourse = _allData.filter(
                (item) => item?.Batches && item?.Batches.length && item?.Batches[0]?.sessionType === 1
            );

            nonRecordedCourse.forEach((item) => {
                if (item?.Batches && item?.Batches.length) {
                    const _startDate = item?.Batches[0]?.startDate;

                    if (_startDate === '0000-00-00') {
                        _comingSoon.push(item);
                    }

                    if (_startDate !== '0000-00-00' && new Date(_startDate) < new Date(currentDate)) {
                        _expired.push(item);
                    }

                    if (_startDate !== '0000-00-00' && new Date(_startDate) >= new Date(currentDate)) {
                        _upcoming.push(item);
                    }
                }
            });

            _expired = _expired.sort((a, b) => new Date(b?.Batches[0]?.startDate) - new Date(a?.Batches[0]?.startDate));
            _upcoming = _upcoming.sort(
                (a, b) => new Date(a?.Batches[0]?.startDate) - new Date(b?.Batches[0]?.startDate)
            );

            let _finalResult = [..._upcoming, ...recordedCourse, ..._expired, ..._comingSoon];

            const _allMasterclasses = [];

            _finalResult.map((item) => {
                if (item?.CouponDetails && item?.CouponDetails.length) {
                    let _coupons = Array.from(item?.CouponDetails);
                    const _noExpiryCoupons = _coupons.filter((item) => item?.Coupon?.endDate === '0000-00-00');
                    const _expiryCoupons = _coupons.filter(
                        (item) => moment(new Date(item?.Coupon?.endDate)) >= new Date(currentDate)
                    );
                    let _allCoupons = [..._noExpiryCoupons, ..._expiryCoupons].map((item) => ({ ...item?.Coupon }));

                    _allCoupons =
                        _allCoupons.length > 1 ? _allCoupons.sort((a, b) => b?.discount - a?.discount) : _allCoupons;

                    _allMasterclasses.push({
                        ...item,
                        CouponDetails: _allCoupons,
                    });
                } else {
                    _allMasterclasses.push(item);
                }
            });

            return _allMasterclasses;
        } else {
            return [];
        }
    } catch (error) {
        console.log('Something went wrong ⚠️');
    }
});

const masterclassesSlice = createSlice({
    initialState: {
        masterclasses: [],
        isFetching: false,
        success: false,
    },
    name: 'masterclasses',
    reducers: {
        getMasterclassById: (state, action) => {
            const masterclass = state.masterclasses.find((item) => item.id === action.payload.id);
            return masterclass;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMasterclasses.pending, (state) => {
                state.isFetching = true;
                state.masterclasses = [];
            })
            .addCase(fetchMasterclasses.fulfilled, (state, action) => {
                state.isFetching = false;
                if (action.payload && action.payload.length) {
                    state.masterclasses = action.payload;
                } else {
                    state.masterclass = [];
                }
                state.success = true;
            })
            .addCase(fetchMasterclasses.rejected, (state) => {
                state.isFetching = false;
                state.masterclasses = [];
            });
    },
});

export const selectMasterclases = (state) => state.masterclasses;

export default masterclassesSlice.reducer;
