import moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getHomepageCustomList } from '../../services/courseServices';

export const fetchHomepageCustomList = createAsyncThunk('/api/homepage/sections/all', async () => {
    try {
        const resp = await getHomepageCustomList();
        const _data = [];

        const currentDate = moment(new Date()).format('YYYY-MM-DD');

        if (resp.data.data && resp.data.data.length) {
            Array.from(resp.data.data).forEach((item) => {
                const _workshops = [];
                if (item?.HomepageSections && item?.HomepageSections.length) {
                    Array.from(item?.HomepageSections).forEach((ws) => {
                        let _workshop = {
                            ...ws?.Workshop,
                            thumbImage: ws?.image,
                            thumbId: ws?.id,
                            Category: {
                                name:
                                    ws?.Workshop?.CourseCategories && ws?.Workshop?.CourseCategories.length
                                        ? Array.from(ws?.Workshop?.CourseCategories).find((ct) => String(ct?.Category?.name).toUpperCase() !== 'CERTIFICATIONS').Category?.name
                                        : '',
                                id:
                                    ws?.Workshop?.CourseCategories && ws?.Workshop?.CourseCategories.length
                                        ? Array.from(ws?.Workshop?.CourseCategories).find((ct) => String(ct?.Category?.name).toUpperCase() !== 'CERTIFICATIONS').Category?.id
                                        : '',
                            },
                        };
                        _workshops.push(_workshop);
                    });
                }

                const _allWorkshops = [];

                _workshops.map((item) => {
                    if (item?.CouponDetails && item?.CouponDetails.length) {
                        let _coupons = Array.from(item?.CouponDetails).filter((item) => item?.Coupon);
                        const _noExpiryCoupons = _coupons.filter((item) => item?.Coupon?.endDate === '0000-00-00');
                        const _expiryCoupons = _coupons.filter(
                            (item) => moment(new Date(item?.Coupon?.endDate)) >= new Date(currentDate)
                        );
                        let _allCoupons = [..._noExpiryCoupons, ..._expiryCoupons].map((item) => ({ ...item?.Coupon }));

                        _allCoupons =
                            _allCoupons.length > 1
                                ? _allCoupons.sort((a, b) => b?.discount - a?.discount)
                                : _allCoupons;

                        _allWorkshops.push({
                            ...item,
                            CouponDetails: _allCoupons,
                        });
                    } else {
                        _allWorkshops.push(item);
                    }
                });

                _data.push({
                    id: item?.id,
                    sectionBgColor: item?.sectionBgColor,
                    textColor: item?.textColor,
                    title: item?.title,
                    type: item?.type,
                    cardType: item?.cardType,
                    workshops: _allWorkshops,
                });
            });
        }

        return _data;
    } catch (error) {
        return error;
    }
});

const customCourseList = createSlice({
    name: 'customCourseList',
    initialState: {
        data: [],
        success: false,
        isFetching: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomepageCustomList.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchHomepageCustomList.fulfilled, (state, action) => {
                state.isFetching = false;
                state.data = action.payload;
                state.success = true;
            })
            .addCase(fetchHomepageCustomList.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.payload;
                state.data = [];
            });
    },
});

export const selectCustomCourseList = (state) => state.customCourseList;

export default customCourseList.reducer;
