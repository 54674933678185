import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getVastuDetails } from '../../services/otherServices';

export const fetchVastuDetails = createAsyncThunk(`/api/vastu/packages`, async () => {
    const _pkgIds = [3, 2, 4, 1];
    try {
        let _pkgs = [];
        let _services = [];
        const { data } = await getVastuDetails()
        if (data.data && data.data.length) {
            const _data = Array.from(data.data).at(0)
            if (_data.ConsultationPackages && _data.ConsultationPackages.length) {
                Array.from(_data.ConsultationPackages).sort((a, b) => _pkgIds.indexOf(a?.id) - _pkgIds.indexOf(b?.id)).forEach((item) => {
                    const covered = [];
                    const detailsRequired = [];

                    if (item?.covered && item?.covered.length) {
                        Array.from(item?.covered).forEach((_covered, ind) => {
                            if (item?.id === 3) {
                                if (ind === item?.covered.length - 1 || ind === item?.covered.length - 2) {
                                    covered.push({
                                        id: ind + 1,
                                        text: _covered,
                                        checked: false
                                    })
                                } else {
                                    covered.push({
                                        id: ind + 1,
                                        text: _covered,
                                        checked: true
                                    })
                                }
                            } else if (item?.id === 2) {
                                if (ind === item?.covered.length - 1) {
                                    covered.push({
                                        id: ind + 1,
                                        text: _covered,
                                        checked: false
                                    })
                                } else {
                                    covered.push({
                                        id: ind + 1,
                                        text: _covered,
                                        checked: true
                                    })
                                }
                            }
                            else {
                                covered.push({
                                    id: ind + 1,
                                    text: _covered,
                                    checked: true
                                })
                            }
                        })
                    }

                    if (item?.detailsRequired && item?.detailsRequired.length) {
                        Array.from(item?.detailsRequired).forEach((_detail, ind) => {
                            detailsRequired.push({
                                id: ind + 1,
                                text: _detail,
                                checked: true
                            })
                        })
                    }
                    _pkgs.push({
                        ...item,
                        covered,
                        detailsRequired
                    })
                })

            }
            if (_data.ConsultationServices && _data.ConsultationServices.length) {
                _services = Array.from(_data.ConsultationServices);
            }
        }

        return {
            services: _services,
            packages: _pkgs
        }
    } catch (error) {
        return {
            services: [],
            packages: [],
            error: 'SOMETHING WENT WRONG::>'
        }
    }
})

const vastuSlice = createSlice({
    initialState: {
        packages: [],
        services: [],
        fetching: true,
        success: false,
        error: null,
    },
    name: 'vastu-slice',
    extraReducers: (builder) => {
        builder.addCase(fetchVastuDetails.pending, (state) => {
            state.fetching = true;
            state.success = false;


        }).addCase(fetchVastuDetails.fulfilled, (state, action) => {
            state.packages = action.payload.packages;
            state.services = action.payload.services;
            state.success = true;
            state.fetching = false;

        }).addCase(fetchVastuDetails.rejected, (state, action) => {
            state.packages = [];
            state.services = [];
            state.fetching = false;
            state.success = false;
            state.error = action.payload.error;
        })
    }
})

export const { addVastuData } = vastuSlice.actions;

export const selectVastu = (state) => state.vastu;

export default vastuSlice.reducer;