import http from './httpService';
import axios from 'axios';

/**************************************
   Currency setup APIs

  1. Get user IP address ->Checked
  2. Get user geolocation data ->Checked
  3. Get currency code from localStorage ->Checked
   
***************************************/

// Get user ip address
export function getUserIPAddress() {
  return axios.get('https://checkip.amazonaws.com/');
}

// Get user geolocation data using IP address
export function getUserGeoLocation() {
  return http.get(`/api/geolocation/locus`);
}

// Get user currency code from localStorage
export const getCurrencyCode = () => {
  let currency = localStorage.getItem('cuid');
  return currency;
};
