import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserGeoLocation } from '../../services/currencyServices';

export const setCurrencyCode = createAsyncThunk('/api/currency', async () => {
    try {
        const response = await getUserGeoLocation();
        const { data } = response;
        if (data.data?.currency?.code) {
            const currentTime = Date.now();
            const _currencyDetails = {
                code: data.data?.currency?.code,
                time: currentTime,
            };
            localStorage.setItem('cuid', JSON.stringify(_currencyDetails));
        }

        return data.data?.currency?.code || 'INR';
    } catch (error) {
        console.log('Something went wrong ⚠️', error);
        return 'INR';
    }
});

const currencySlice = createSlice({
    initialState: {
        currency: '',
        success: false,
    },
    name: 'currency',
    reducers: {
        setCurrency: (state, action) => {
            state.currency = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setCurrencyCode.pending, (state) => {
                state.currency = '';
                state.success = false;
            })
            .addCase(setCurrencyCode.fulfilled, (state, action) => {
                state.currency = action.payload;
                state.success = true;
            })
            .addCase(setCurrencyCode.rejected, (state) => {
                state.currency = '';
                state.success = false;
            });
    },
});

export const { setCurrency } = currencySlice.actions;

export const selectCurrency = (state) => state.currency;

export default currencySlice.reducer;
