import http from './httpService';

/**************************************
  Other services

  1. Get course case studies ->Checked
  2. Get certification details ->Checked
  3. Get banner data
   
***************************************/

// Get all case studies of a workshop (Course studies page)
export function getCourseCaseStudies(id) {
    return http.get(`/api/course/${id}/case-studies`);
}

// Get courses Case Studies (All Case Studies Page)
export function getCoursesCaseStudies(wsIds) {
    return http.post(`/api/course/case-studies`, wsIds);
}

// Get certificate page details using credential id (Certification page)
export function getCertificateCredentials(credId) {
    return http.get(`/api/course/${credId}/certificate`);
}

// Get website header banner data (Homepage & All Courses page)
export function getBanner() {
    return http.get('/api/top-banner');
}

// Get homepage video testimonials
export function getVideoTestimonials(data) {
    return http.post(`/api/video/testimonial/details`, data);
}

// Get all courses, blogs, trips
export function getCoursesBlogsTrips() {
    return http.get(`https://api.kaarwan.com/api/course/blogs/trips`)
}

export function initiateThesisPayment(data) {
    return http.post(`/api/competition/payment/initiate`, data);
}

export function competitionContactUs(data) {
    return http.post(`/api/competitions/contact-us`, data);
}


// Vastu Page Services
export function getVastuDetails() {
    return http.get(`/api/consultation/1/service/package`)
}

export function initiateVastuPayment(data) {
    return http.post(`/api/consultation/payment/initiate`, data)
}