import { createSlice } from '@reduxjs/toolkit';

const blogsSlice = createSlice({
    name: 'blogs',
    initialState: {
        blogs: [],
        blogsCount: 0,
        isFetching: false,
        success: false,
    },
    reducers: {
        storeNewBlog: (state, action) => {
            if (!state.blogs.length) {
                state.blogs = [action.payload];
                state.blogsCount = state.blogsCount + 1;
            } else {
                const alreadyExist = state.blogs.find((item) => item.id === action.payload.id);
                if (!alreadyExist) {
                    state.blogs = [...state.blogs, action.payload];
                    state.blogsCount = state.blogsCount + 1;
                }
            }
        },
        isAlreadyStored: (state, action) => {
            const isAlreadyExist = state.blogs.find((item) => item?.id === action.payload);
            return isAlreadyExist ? true : false;
        },
    },
});

export const { storeNewBlog, isAlreadyStored } = blogsSlice.actions;

export const selectBlogs = (state) => state.blogs;

export default blogsSlice.reducer;
