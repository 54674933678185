import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getTripHomepageDetails } from '../../services/travelServices';

export const fetchLandingTripPageData = createAsyncThunk('/api/trip/homepage', async () => {
    try {
        const resp = await getTripHomepageDetails();
        let { data } = resp.data;
        if (data && data.length) {
            data = Array.from(data).sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate));
            const _upcoming = Array.from(data).filter((item) => new Date(item?.startDate) > new Date());
            const _completed = Array.from(data).filter((item) => new Date(item?.startDate) <= new Date());
            return {
                upcoming: _upcoming,
                completed: _completed,
            };
        } else {
            return {
                upcoming: [],
                completed: [],
            };
        }
    } catch (error) {
        console.log('Something went wrong ⚠️');
    }
});

const landingPageTripSlice = createSlice({
    name: 'landingPageTrips',
    initialState: {
        trips: {
            upcoming: [],
            completed: [],
        },
        success: false,
        isFetching: false,
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchLandingTripPageData.pending, (state) => {
                state.success = false;
                state.isFetching = true;
            })
            .addCase(fetchLandingTripPageData.fulfilled, (state, action) => {
                state.trips.upcoming = action.payload?.upcoming || [];
                state.trips.completed = action.payload?.completed || [];
                state.success = true;
                state.isFetching = false;
            })
            .addCase(fetchLandingTripPageData.rejected, (state) => {
                state.success = false;
                state.isFetching = false;
            });
    },
});

export const selectLandingPageTrips = (state) => state.landingPageTrips;

export default landingPageTripSlice.reducer;
