import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCourseDetails } from '../../../services/dashboardServices';

export const fetchDashboardCoursesOverview = createAsyncThunk('/api/dashboard/courses/overview', async () => {
    try {
        const resp = await getCourseDetails();

        let _ongoing = [],
            _upcoming = [],
            _completed = [],
            _suggested = [],
            _dropdown = [],
            _enrolledCourses = [];

        // Suggested Courses
        if (resp.data.data.suggestedCourses && resp.data.data.suggestedCourses.length) {
            _suggested = resp.data.data.suggestedCourses;
        }

        // Enrolled Courses
        if (resp.data.data.enrolledCourses && resp.data.data.enrolledCourses.length) {
            Array.from(resp.data.data.enrolledCourses).forEach((item) => {
                _enrolledCourses.push(item);
            });
        }

        if (_enrolledCourses.length) {
            // Completed
            _enrolledCourses.forEach((item) => {
                if (item?.Batch?.Workshop?.type === 1) {
                    if (new Date(`${item?.Batch?.endDate}T${item?.Batch?.BatchSchedules[0]?.endTime}`) < new Date()) {
                        _completed.push(item);
                    }
                }
                if (item?.Batch?.Workshop?.type === 2) {
                    if (new Date(`${item?.Batch?.endDate}`) < new Date()) {
                        _completed.push(item);
                    }
                }
            });

            // Upcoming
            _enrolledCourses.forEach((item) => {
                if (item?.Batch?.Workshop?.type === 1) {
                    if (
                        new Date(`${item?.Batch?.startDate}T${item?.Batch?.BatchSchedules[0]?.startTime}`) > new Date()
                    ) {
                        _upcoming.push(item);
                    }
                }
                if (item?.Batch?.Workshop?.type === 2) {
                    if (new Date(`${item?.Batch?.startDate}`) > new Date()) {
                        _upcoming.push(item);
                    }
                }
            });

            // Ongoing
            _enrolledCourses.forEach((item) => {
                if (item?.Batch?.Workshop?.type === 1) {
                    if (
                        new Date() >=
                            new Date(`${item?.Batch?.startDate}T${item?.Batch?.BatchSchedules[0]?.startTime}`) &&
                        new Date() <= new Date(`${item?.Batch?.endDate}T${item?.Batch?.BatchSchedules[0]?.endTime}`)
                    ) {
                        _ongoing.push(item);
                    }
                }
                if (item?.Batch?.Workshop?.type === 2) {
                    if (
                        new Date() >= new Date(`${item?.Batch?.startDate}`) &&
                        new Date() <= new Date(`${item?.Batch?.endDate}`)
                    ) {
                        _ongoing.push(item);
                    }
                }
            });

            _completed = _completed
                .sort((a, b) => new Date(b?.Batch?.endDate) - new Date(a?.Batch?.endDate))
                .map((item) => ({ ...item, isDropdown: false, isCompleted: true }));
            _ongoing = _ongoing
                .sort((a, b) => new Date(a?.Batch?.startDate) - new Date(b?.Batch?.startDate))
                .map((item) => ({ ...item, isDropdown: true, isCompleted: false }));
            _upcoming = _upcoming
                .sort((a, b) => new Date(a?.Batch?.startDate) - new Date(b?.Batch?.startDate))
                .map((item) => ({ ...item, isDropdown: true, isCompleted: false }));

            _dropdown = [..._ongoing, ..._upcoming, ..._completed];
        }

        return {
            enrolled: resp.data.data.enrolledCourses,
            suggested: _suggested,
            dropdown: _dropdown,
        };
    } catch (error) {
        return {
            enrolled: [],
            suggested: [],
            dropdown: [],
        };
    }
});

const coursesOverviewSlice = createSlice({
    initialState: {
        courses: [],
        suggested: [],
        dropdownCourses: [],
        totalCourses: 0,
        overviewData: {},
        success: false,
        isFetching: false,
        error: null,
    },
    name: 'dashboardCoursesList',
    reducers: {
        setCourseOverviewData: (state, action) => {
            state.overviewData = action.payload;
        },
        setSuggestedCourses: (state, action) => {
            state.suggested = action.payload;
        },
        clearCourseOverviewData: (state) => {
            state.courses = [];
            state.success = false;
            state.dropdownCourses = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardCoursesOverview.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchDashboardCoursesOverview.fulfilled, (state, action) => {
                state.isFetching = false;
                state.courses = action.payload.enrolled;
                state.suggested = action.payload.suggested;
                state.dropdownCourses = action.payload.dropdown;
                state.totalCourses = action.payload.dropdown.length;
                state.success = true;
                state.error = null;
                if (Array.from(action.payload.dropdown).length === 1) {
                    state.overviewData = Array.from(action.payload.dropdown).at(0);
                }
            })
            .addCase(fetchDashboardCoursesOverview.rejected, (state) => {
                state.isFetching = false;
                state.courses = [];
                state.success = false;
                state.error = 'Something went wrong ⚠️';
            });
    },
});

export const { setCourseOverviewData, clearCourseOverviewData, setSuggestedCourses } = coursesOverviewSlice.actions;

export const selectOverviewCourses = (state) => state.overviewCourses;

export default coursesOverviewSlice.reducer;
