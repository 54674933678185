import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getToken, setToken } from '../../services/authServices';
import { getUserProfile } from '../../services/accountServices';

export const fetchUserDetails = createAsyncThunk('/api/user/profile', async () => {
    setToken(getToken());
    const _User = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
    let userData = {
        id: '',
        email: '',
        name: '',
        mobile: '',
        gender: '',
        city: '',
        dob: '',
        iam: '',
        upiId: '',
        college: '',
        instagram: '',
        linkedin: '',
        imagePath: '',
        referCode: '',
        secretKey: '',
        isVerified: false,
        profileCompleted: false,
    };

    if (!_User.id) {
        return {
            user: {},
        };
    }

    try {
        const res = await getUserProfile({
            id: _User.id,
            email: _User.email,
        });


        let trips = [];

        if (res.data.data.user) {
            const user = res.data.data.user;
            userData['id'] = _User.id || '';
            userData['email'] = _User.email || '';
            userData['name'] = user?.name || '';
            userData['mobile'] = user?.mobile || '';
            userData['upiId'] = user?.upiId || '';
            userData['gender'] = user?.gender || '';
            userData['city'] = user?.city || '';
            userData['dob'] = user?.dob || '';
            userData['iam'] = user?.UserDetail?.iam || '';
            userData['college'] = user?.UserDetail?.college || '';
            userData['instagram'] = user?.UserDetail?.instagram || '';
            userData['linkedin'] = user?.UserDetail?.linkedin || '';
            userData['behance'] = user?.UserDetail?.behance || '';
            userData['dribbble'] = user?.UserDetail?.dribbble || '';
            userData['imagePath'] = user?.image || '';
            userData['isVerified'] = user?.isVerified || false;
            userData['referCode'] = user?.referCode || '';
            userData['secretKey'] = user?.secretKey || '';

            if (
                userData['email'] &&
                userData['name'] &&
                userData['gender'] &&
                userData['dob'] &&
                userData['city'] &&
                userData['iam']
            ) {
                userData['profileCompleted'] = true;
            } else {
                userData['profileCompleted'] = false;
            }
        }

        if (res.data.data.trips && res.data.data.trips.length) {
            trips = Array.from(res.data.data.trips);
        }

        return {
            trips: trips,
            user: userData,
        };
    } catch (error) {
        return {
            trips: {},
            user: {},
        };
    }
});

const userSlice = createSlice({
    initialState: {
        userDetails: {},
        trips: [],
        isFetching: true,
        success: false,
        isLoggedIn: false,
        profileChangesMade: false,
        applicationChangesMade: false,
        requiredDataFilled: false,
    },
    name: 'user',
    reducers: {
        onLogoutHandle: (state) => {
            state.userDetails = {};
            state.trips = [];
            state.success = false;
            state.isFetching = false;
            state.isLoggedIn = false;
            state.requiredDataFilled = false;
            state.applicationChangesMade = false;
            state.profileChangesMade = false;
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('isMod');
            setToken(null);
        },
        setProfileChanges: (state, action) => {
            state.profileChangesMade = action.payload;
        },
        setApplicationChanges: (state, action) => {
            state.applicationChangesMade = action.payload;
        },
        setRequiredFieldStatus: (state, action) => {
            state.requiredDataFilled = action.payload;
        },
        updateProfileImagePath: (state, action) => {
            state.userDetails.imagePath = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDetails.pending, (state) => {
                state.isFetching = true;
                state.success = false;
                state.userDetails = {};
                state.isLoggedIn = false;
            })
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.isFetching = false;
                state.userDetails = action.payload.user;
                state.trips = action.payload.trips;
                state.requiredDataFilled = action.payload.user.profileCompleted;
                state.success = true;
                state.isLoggedIn = true;
            })
            .addCase(fetchUserDetails.rejected, (state) => {
                state.isFetching = false;
                state.userDetails = {};
                state.success = false;
                state.isLoggedIn = false;
            });
    },
});

export const {
    onLogoutHandle,
    setProfileChanges,
    setApplicationChanges,
    setRequiredFieldStatus,
    updateProfileImagePath,
} = userSlice.actions;

export const selectUser = (state) => state.user;

export default userSlice.reducer;
