import { createSlice } from '@reduxjs/toolkit';

const tripsDetails = createSlice({
    name: 'tripDetails',
    initialState: {
        trips: [],
        loading: false,
        error: null,
        isFetching: false,
    },
    reducers: {
        addNewTripDetails: (state, action) => {
            if (state.trips.length) {
                const isExist = state.trips.find((item) => item?.id === parseInt(action.payload.id));
                if (!isExist) {
                    state.trips = [...state.trips, action.payload];
                }
            } else {
                state.trips = [action.payload];
            }
        },
        getTripDetails: (state, action) => {
            if (state.trips.length) {
                return state.trips.find((item) => item?.id === parseInt(action.payload));
            }
        },
    },
});

export const { addNewTripDetails, getTripDetails } = tripsDetails.actions;

export const selectTripDetails = (state) => state.tripDetails;

export default tripsDetails.reducer;
