import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getWebHomepageBlogs } from '../../services/blogServices';

export const fetchHomepageBlogs = createAsyncThunk('/website/homepage/blogs', async () => {
    try {
        const resp = await getWebHomepageBlogs();
        return resp.data.data;
    } catch (error) {
        return [];
    }
});

const homepageBlogs = createSlice({
    initialState: {
        blogs: [],
        isFetching: false,
        success: false,
    },
    name: 'homepageBlogs',
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomepageBlogs.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchHomepageBlogs.fulfilled, (state, action) => {
                state.blogs = action.payload;
                state.isFetching = false;
                state.success = true;
            })
            .addCase(fetchHomepageBlogs.rejected, (state) => {
                state.success = false;
                state.isFetching = false;
                state.blogs = [];
            });
    },
});

export const selectHomepageBlogs = (state) => state.homepageBlogs;

export default homepageBlogs.reducer;
