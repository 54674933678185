import { createSlice } from '@reduxjs/toolkit';

const userApplicationSlice = createSlice({
    name: 'applications',
    initialState: {
        applications: [],
    },
    reducers: {
        addUserApplication: (state, action) => {
            if (action.payload) {
                const { id } = action.payload;
                if (state.applications.length > 0) {
                    const isAdded = state.applications.find((item) => item.id === id);
                    if (!isAdded) {
                        state.applications = [...state.applications, action.payload];
                    }
                } else {
                    state.applications.push(action.payload);
                }
            }
        },

        updateUserApplication: (state, action) => {
            const _newTrips = state.applications.filter(
                (item) => parseInt(item.id, 10) !== parseInt(action.payload.id, 10)
            );
            state.applications = [..._newTrips, action.payload.data];
        },
    },
});

export const { addUserApplication, updateUserApplication } = userApplicationSlice.actions;

export const selectApplications = (state) => state.applications;
export default userApplicationSlice.reducer;
