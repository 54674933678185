import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getToken, setToken } from '../../../services/authServices';
import { getUserProfile } from '../../../services/accountServices';

export const fetchUserDetailsVer2 = createAsyncThunk('/v2/api/user/details', async () => {
    setToken(getToken());
    const _cred = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
    if (!_cred) return {};
    try {
        const { data } = await getUserProfile({
            id: _cred.id,
            email: _cred.email,
        });

        if (data.code === 200) {
            return {
                user: data.data.user,
                trips: data.data.trips,
            };
        } else {
            return {
                user: {},
                trips: [],
            };
        }
    } catch (error) {
        return {};
    }
});

const userSliceVer2 = createSlice({
    initialState: {
        userDetails: {},
        trips: [],
        isFetching: true,
        success: false,
        isLoggedIn: false,
    },
    name: 'user-ver2',
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserDetailsVer2.pending, (state) => {
                state.isFetching = true;
                state.success = false;
                state.userDetails = {};
                state.isLoggedIn = false;
            })
            .addCase(fetchUserDetailsVer2.fulfilled, (state, action) => {
                state.isFetching = false;
                state.userDetails = action.payload.user;
                state.trips = action.payload.trips;
                state.success = true;
                state.isLoggedIn = true;
            })
            .addCase(fetchUserDetailsVer2.rejected, (state) => {
                state.isFetching = false;
                state.userDetails = {};
                state.success = false;
                state.isLoggedIn = false;
            });
    },
});

export const selectUserInfo = (state) => state.userVer2;

export default userSliceVer2.reducer;
